import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import LinkIcon from '@mui/icons-material/Link';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { XIcon } from './Icons';
import { structureViewPostUrl } from 'helper';
import { PostDto } from 'models/post';
declare global {
  interface Window {
    FB: any;
  }
  const FB: any;
}

const MenuIcon = styled('div')({
  marginRight: 8,
  height: 18,
  width: 18,
  marginTop: -2,
});

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const Avatar = styled('div')({
  width: 36,
  height: 36,
  backgroundColor: '#0f172a',
  color: '#fff',
  fontSize: 14,
  fontWeight: 600,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const StyledMenuItem = styled(MenuItem)({
  alignItems:'normal',
  'div:last-child':{
    marginTop:1
  }
});

function ShareMenu(props: PostDto) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef(null);
  const {institute_name,route_path} = props;



  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleGenerateLink = () => {
    const url =`https://association-qa.aveon.io${structureViewPostUrl(route_path,institute_name)}`;
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setIsCopied(false);
        timeoutRef.current = null;
      }, 4000);
    });
  };

  return (
    
    <>
      <IconButton
        size='small'
        disableRipple
        onClick={handleClick}
        aria-controls={open ? 'share-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <IosShareIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='share-menu'
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={menuProps}
      >
        <StyledMenuItem onClick={handleGenerateLink}>
          <MenuIcon>
            {!isCopied ?
              (<LinkIcon style={{transform:'rotateZ(315deg)'}}/>) : 
              (<CheckCircleOutlinedIcon style={{ color: 'green',transform:'rotateZ(0deg)' }} />)}
          </MenuIcon>
          <div>Copy link</div>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem disabled>
          <MenuIcon>
            <XIcon />
          </MenuIcon>
          <div>Share on Twitter</div>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem disabled>
          <MenuIcon>
            <FacebookIcon />
          </MenuIcon>
          <div>Share on Facebook</div>
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem disabled>
          <MenuIcon>
            <LinkedInIcon />
          </MenuIcon>
          <div>Share on LinkedIn</div>
        </StyledMenuItem>
      </Menu>
    </>
  );
}

export default ShareMenu;
