import './editor.scss';
import { ReactNode, useState } from 'react';
import { $createParagraphNode, $getRoot, EditorState, LexicalEditor, SerializedEditorState } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { SharedHistoryContext, useSharedHistoryContext } from './context/SharedHistoryContext';
import { TableContext } from './plugins/TablePlugin';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import Placeholder from './ui/Placeholder';
import ContentEditable from './ui/ContentEditable';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import { LayoutPlugin } from './plugins/LayoutPlugin/LayoutPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import PollPlugin from './plugins/PollPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import DefaultValuePlugin from './plugins/DefaultValuePlugin';
import EditorNodes from './nodes/EditorNodes';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { $createHeadingNode } from '@lexical/rich-text';

type EditorProps = {
  placeholder?: ReactNode | string
  onChange?: (content: string, editorState: SerializedEditorState) => void
  content?: string
  postId?:string
};

export default function Editor(props: EditorProps) {
  const { historyState } = useSharedHistoryContext();
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);

  const initialConfig = {
    namespace: 'Edualliance',
    onError(error: Error) {
      throw error;
    },
    nodes: [...EditorNodes],
    theme: PlaygroundEditorTheme,
    editorState: () => {
      if (!props.content) {
        const heading = $createHeadingNode('h3');
        const paragraph = $createParagraphNode();
        $getRoot().append(heading, paragraph);
        $getRoot().selectStart();
      }
    }
  };

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    if (props.onChange) {
      editor.update(() => {
        const content = $generateHtmlFromNodes(editor);
        props.onChange(content, editorState.toJSON());
      });
    }
  };

  return (
    <LexicalComposer initialConfig={initialConfig} key={props.postId}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <div className='editor-shell'>
              <div className='editor-container'>
                <RichTextPlugin
                  contentEditable={
                    <div className="editor-scroller">
                      <div className="editor" ref={onRef}>
                        <ContentEditable />
                      </div>
                    </div>
                  }
                  placeholder={<Placeholder>{props.placeholder}</Placeholder>}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <AutoFocusPlugin />
                <CheckListPlugin />
                <ClearEditorPlugin />
                <HashtagPlugin />
                <HistoryPlugin externalHistoryState={historyState} />
                <HorizontalRulePlugin />
                <ListPlugin />
                <TabIndentationPlugin />
                <TablePlugin />
                {/* <AutocompletePlugin /> */}
                <AutoEmbedPlugin />
                <AutoLinkPlugin />
                <CodeHighlightPlugin />
                <CollapsiblePlugin />
                <ComponentPickerPlugin />
                {/* <ContextMenuPlugin /> */}
                <DragDropPaste />
                <EmojiPickerPlugin />
                <EmojisPlugin />
                <FigmaPlugin />
                <ImagesPlugin />
                {/* <InlineImagePlugin /> */}
                <KeywordsPlugin />
                <LayoutPlugin />
                <LinkPlugin />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <MarkdownShortcutPlugin />
                <MentionsPlugin />
                <PollPlugin />
                <TabFocusPlugin />
                <TableCellResizer />
                <TwitterPlugin />
                <YouTubePlugin />
                {floatingAnchorElem && (
                  <>
                    <FloatingLinkEditorPlugin
                      anchorElem={floatingAnchorElem}
                      isLinkEditMode={isLinkEditMode}
                      setIsLinkEditMode={setIsLinkEditMode} />
                    <FloatingTextFormatToolbarPlugin
                      anchorElem={floatingAnchorElem}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                    <TableCellActionMenuPlugin
                      anchorElem={floatingAnchorElem}
                      cellMerge={true} />
                  </>
                )}
                <OnChangePlugin onChange={handleChange} ignoreSelectionChange ignoreHistoryMergeTagChange />
                <DefaultValuePlugin value={props.content} />
              </div>
            </div>
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
