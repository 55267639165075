import { usePostViewStore } from 'store/post-view';
import {
  StyledAvatarRow,
  StyledH1,
  StyledPost,
  StyledRow,
  StyledSearchTopic,
  StyledSpan,
  StyledSubTitle,
  StyledTitle,
} from '../searchQuery/searchQuery';
import { Avatar } from 'views/component/ProfileMenu';
import {
  StyledDivider,
  StyledIconContent,
  StyledPostImages,
  StyledSvgIcon,
  StyledText,
  TextMargin,
} from 'views/component/element/CardView';
import { initialValue } from 'gateways/util';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { listPosts } from 'gateways/post';
import { PostListParams, PostType } from 'models/post';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import TimeElapsed from 'utils/time-utils';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { MessageIcon } from 'views/component/element/Icons';
import { ContentLinear } from 'views/component/Loader/Loading';
import { structureViewPostUrl } from 'helper';

export function Author({ userId,institution }: { userId: string,institution?:string }) {
  const { userProfiles } = usePostViewStore();
  const user = userProfiles.get(userId.toLowerCase());
  return (
    <StyledAvatarRow size='xs'>
      <Avatar sx={{ height: 20, width: 20 }}>
        <StyledText
          sx={{
            fontSize: 8,
            textAlign: 'center',
            color: '#ffffff',
            marginTop: '1px !important',
          }}
        >
          {initialValue(user?.name)}
        </StyledText>
      </Avatar>
      <StyledText
        sx={{
          fontSize: 12,
          marginLeft: '-6px',
          textOverflow: 'unset',
          width: 'unset',
          textTransform: 'capitalize',
        }}
      >
        {user?.name ? user?.name : 'Unknown User'}
      </StyledText>
      {institution && (<StyledText sx={{
        fontSize: 12,
        marginLeft:'-8px',
      }}>{`in ${institution}`}</StyledText>)}
    </StyledAvatarRow>
  );
}

const OverAllPost = () => {
  const [list, setList] = useState([]);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage , setHasNextPage] = useState(true);
  const navigate = useNavigate();

  async function getPostListByType() {
    const params: PostListParams = {
      limit: 25,
      page: page,
    };
    if(location.search.length > 0){
      const str = location.search;
      const urlParams = new URLSearchParams(str);
      const tagId = urlParams.get('tagId');
      params.tagId = +tagId;
    } else{
      const type = location.pathname.split('/')[2];
      params.type = type as PostType;
    }
    const typeList = await listPosts(params);
    setHasNextPage(!(typeList.metadata.current_page === typeList.metadata.last_page));
    setList(previousData => [...previousData, ...typeList.collection]);
    setIsLoading(false);
  }

  useEffect(() => {
    getPostListByType();
  }, [page,location]);

  //infinity scroll
  window.onscroll = function () {
    const tolerance = 1;
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (Math.abs(scrollPosition - pageHeight) <= tolerance && !isLoading && hasNextPage) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    setPage(page + 1);
  };

  return (
    <>
      <StyledSearchTopic size='sm'>
        <StyledRow size='xs'>
          <StyledH1 sx={{ marginBottom: '24px' }}>
            <StyledSpan>Here is the overall </StyledSpan>{' '}
            {location.pathname.split('/')[2]}
          </StyledH1>
        </StyledRow>
        <FlexColumn
          size='xs'
          style={{
            width: '100%',
            height: !list?.length ? '88vh' : '',
            padding: '0 24px',
          }}
        >
          {!list.length && isLoading ? (
            <>
              <ContentLinear />
            </>
          ) : (
            <>
              {list?.map(data => (
                <div key={data.post_id}>
                  <StyledPost size='xs'>
                    <FlexColumn size='xs'>
                      <Author userId={data.user_id} institution={data?.institute_name}/>
                      <StyledTitle
                        size='xs'
                        onClick={() =>
                          navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                        }
                      >
                        {data.title}
                      </StyledTitle>
                      <StyledSubTitle
                        size='xs'
                        onClick={() =>
                          navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                        }
                      >
                        {data.subtitle}
                      </StyledSubTitle>
                      <FlexRow size='xs'>
                        <StyledIconContent
                          size='xs'
                          sx={{
                            color: '#6d6d6d',
                            fontWeight: 'unset',
                            marginTop: 0,
                          }}
                        >
                          <TimeElapsed
                            postedTime={
                              new Date(data?.published_date_time)
                            }
                          />
                        </StyledIconContent>
                        <FlexColumn size='xs'>
                          {data?.post?.is_allow_reactions && (<StyledIconContent size='xs'>
                            {data?.reactions?.metadata.map(reactions => (
                              <FlexRow size='xs' key={reactions.type}>
                                {reactions.type === 'Like' && (
                                  <>
                                    {data.reactions?.collection.find(
                                      likedUser =>
                                        likedUser?.user_id.toLowerCase() ===
                                        'like'
                                    ) ? (
                                        <StyledSvgIcon>
                                          <ThumbUpAltIcon />
                                        </StyledSvgIcon>
                                      ) : (
                                        <StyledSvgIcon>
                                          <ThumbUpOffAltIcon />
                                        </StyledSvgIcon>
                                      )}
                                  </>
                                )}
                                <TextMargin>{reactions?.count}</TextMargin>
                              </FlexRow>
                            ))}
                            {data.reactions.metadata.length === 0 && (
                              <FlexRow size='xs'>
                                <StyledSvgIcon>
                                  <ThumbUpOffAltIcon />
                                </StyledSvgIcon>
                                <TextMargin>0</TextMargin>
                              </FlexRow>
                            )}
                          </StyledIconContent>)}
                        </FlexColumn>
                        {data?.post?.is_allow_comments && (<FlexColumn size='xs'>
                          <StyledIconContent size='xs'>
                            <StyledSvgIcon
                              sx={{
                                path: {
                                  fill: 'rgb(233, 90, 8)',
                                },
                              }}
                            >
                              <MessageIcon />
                            </StyledSvgIcon>
                            {data?.comments_count || 0}
                          </StyledIconContent>
                        </FlexColumn>)}
                      </FlexRow>
                    </FlexColumn>
                    <FlexColumn size='xs'>
                      {data.cover_image_link && <StyledPostImages
                        onClick={() =>
                          navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                        }
                        style={{cursor: data.cover_image_link ? 'pointer' : ''}}
                      >
                        {data.cover_image_link && (
                          <img
                            src={data.cover_image_link}
                            alt='eventImage'
                            style={{
                              width: '116px',
                              height: '100px',
                              objectFit: 'cover',
                            }}
                          />
                        )}
                      </StyledPostImages> }
                    </FlexColumn>
                  </StyledPost>
                  <StyledDivider />
                </div>
              ))}
            </>
          )}
        </FlexColumn>
      </StyledSearchTopic>
    </>
  );
};

export default OverAllPost;
