import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import { listTags } from 'gateways/tag';
import { TagDto } from 'models/post';
import { FocusEventHandler, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';

type TagAutocompleteProps = {
  value: TagDto[]
  onChange: (tags: TagDto[]) => void
  onBlur: FocusEventHandler<HTMLDivElement>
}

function TagAutocomplete(props: TagAutocompleteProps) {
  const [options, setOptions] = useState<readonly TagDto[]>([]);
  const [values, setValues] = useState<TagDto[]>(props.value || []);
  const [inputValue, setInputValue] = useState('');

  const fetchTags = useMemo(() => debounce(async (query: string, onResults: (results?: TagDto[]) => void) => {
    const tagsPage = await listTags(query, 10);
    onResults(tagsPage.collection);
  }, 400), []);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(values ? [...values] : []);
      return undefined;
    }
    
    if (!isLimitReached){
      fetchTags(inputValue, (results?: TagDto[]) => {
        if (active) {
          let newOptions: readonly TagDto[] = [];

          if (values) {
            newOptions = [...values];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [values, inputValue, fetchTags]);

  const handleChange = (_event: SyntheticEvent, values?: TagDto[]) => {
    if (values.length > 5) {
      setValues(prev=> prev.slice(0,5));
      return;
    }
    values = values.map(tag => typeof tag === 'string' ? { name: tag as unknown } as TagDto : tag);
    values = [...new Map(values.map(tag => [tag.name, tag])).values()];
    setOptions(values ? [...values, ...options] : options);
    setValues(values);
    props.onChange(values);
  };

  const handleInputChange = (_event: SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const getOptionLabel = (option: string | TagDto, renderUsage?: boolean) => {
    if (typeof option === 'string') {
      return option;
    }

    if (renderUsage && option.post_count) {
      return `${option.name} (${option.post_count})`;
    }

    return option.name;
  };

  const isLimitReached = values?.length >= 5;

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      filterOptions={x => x}
      options={options}
      autoComplete
      multiple
      freeSolo
      includeInputInList
      filterSelectedOptions
      value={values}
      noOptionsText="No topics"
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={isLimitReached ? '' : 'Topics'}
          disabled={isLimitReached}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          {getOptionLabel(option, true)}
        </li>
      )}
      onBlur={props.onBlur}
    />
  );
}

export default function FieldTagAutocomplete(props: { name: string }) {
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const handleChange = (tags: TagDto[]) => {
    setTimeout(() => {
      setValue(tags, true);
    }, 5000);
  };
  return (
    <TagAutocomplete value={value} onChange={handleChange} onBlur={field.onBlur}/>
  );
}
