import {
  StyledPost,
  StyledSubTitle,
  StyledTitle,
} from 'views/pages/searchQuery/searchQuery';
import { FlexColumn, FlexRow } from '../styled/flex';
import { FullNameAvatar, StyledDivider } from 'views/pages/post/PostSettings';
import {
  StyledCard,
  StyledContentDivider,
  StyledIconContent,
  StyledPostImages,
} from '../element/CardView';
import { CardContent, styled } from '@mui/material';
import {
  StyledColumns,
  StyledTitleRow,
  TopContent,
} from 'views/pages/topics/topics';
import {
  Container,
  StyledHomePage,
  StyledRow,
} from 'views/pages/post/PostHome';

export const LinearStyled = styled(FlexColumn)({
  '& div:not(:last-child)': {
    height: '14px',
    minWidth: '340px',
  },
});

export const LinearRow = styled(FlexRow)({
  justifyContent: 'space-evenly',
  width: '100%',
});

export const LinearStyledInAll = styled(LinearStyled)({
  '& div': {
    background:
      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
    animationDuration: '2s',
  },
  width: '-webkit-fill-available',
});

const StyledCardTitleRow = styled(StyledTitle)({
  background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
  animationDuration: '2s',
});

const StyledCardSubTitleRow = styled(StyledSubTitle)({
  background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
  animationDuration: '2s',
});

const StyledCardHeader = styled(FlexRow)({
  height: 28,
  width: 100,
  background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
  animationDuration: '4.5s',
});

export const CardLinear = () => {
  const loadingCount = [1, 2];
  return (
    <div style={{ width: '100%', background: 'white', borderRadius: 10 }}>
      <StyledCard>
        <StyledCardHeader
          size='sm'
          className='linear-background'
        ></StyledCardHeader>
      </StyledCard>
      <StyledDivider style={{ margin: 0 }} />
      {loadingCount.map(() => (
        <>
          <StyledPost
            size='xs'
            style={{ background: 'unset', marginTop: 20, padding: '0 20px' }}
          >
            <LinearStyledInAll size='xs'>
              <StyledTitle
                size='xs'
                className='linear-background'
              ></StyledTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <FlexRow size='xs' style={{ width: 100 }}>
                <StyledSubTitle
                  size='xs'
                  className='linear-background'
                  style={{
                    width: 217,
                    height: 14,
                    animationDuration: '8s',
                  }}
                ></StyledSubTitle>
              </FlexRow>
            </LinearStyledInAll>
            <FlexColumn size='xs'>
              <StyledPostImages className='linear-background'></StyledPostImages>
            </FlexColumn>
          </StyledPost>
          <StyledDivider />
        </>
      ))}
    </div>
  );
};

export const ContentLinear = () => {
  const loadingCount = [1, 2];
  return (
    <div>
      {loadingCount.map((_, i) => (
        <div key={i}>
          <StyledPost size='xs' style={{ background: 'unset', marginTop: 20 }}>
            <LinearStyledInAll size='xs'>
              <StyledTitle
                size='xs'
                className='linear-background'
              ></StyledTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <StyledSubTitle
                size='xs'
                className='linear-background'
              ></StyledSubTitle>
              <FlexRow size='xs' style={{ width: 100 }}>
                <StyledSubTitle
                  size='xs'
                  className='linear-background'
                  style={{
                    width: 217,
                    height: 14,
                    animationDuration: '8s',
                  }}
                ></StyledSubTitle>
              </FlexRow>
            </LinearStyledInAll>
            <FlexColumn size='xs'>
              <StyledPostImages className='linear-background'></StyledPostImages>
            </FlexColumn>
          </StyledPost>
          <StyledDivider />
        </div>
      ))}
    </div>
  );
};

export const ContentCardView = () => {
  return (
    <>
      <CardContent>
        <StyledContentDivider size='xs'>
          <LinearRow size='xs'>
            <LinearStyled size='xs'>
              <StyledCardTitleRow
                className='linear-background'
                size={'xxs'}
              ></StyledCardTitleRow>
              <StyledCardTitleRow
                className='linear-background'
                size={'xxs'}
              ></StyledCardTitleRow>
              <StyledCardSubTitleRow
                className='linear-background'
                size={'xxs'}
              ></StyledCardSubTitleRow>
              <StyledCardSubTitleRow
                className='linear-background'
                size={'xxs'}
              ></StyledCardSubTitleRow>
              <div
                className='linear-background'
                style={{ width: '100px', height: '14px' }}
              ></div>
            </LinearStyled>
            <StyledPostImages className='linear-background'></StyledPostImages>
          </LinearRow>
        </StyledContentDivider>
      </CardContent>
    </>
  );
};

export const TopicView = () => {
  const loadingCount = [1, 2];
  return (
    <>
      <div>
        <TopContent size='xs'>
          {loadingCount.map(() => (
            // eslint-disable-next-line react/jsx-key
            <FlexRow size='md'>
              <StyledColumns size={'xs'}>
                <StyledTitleRow
                  className='linear-background'
                  style={{ height: 200 }}
                ></StyledTitleRow>
                <StyledTitleRow
                  className='linear-background'
                  style={{ height: 20 }}
                ></StyledTitleRow>
                <StyledIconContent
                  size='xs'
                  className='linear-background'
                  style={{
                    height: 20,
                    background:
                      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
                  }}
                ></StyledIconContent>
                <StyledIconContent
                  size='xs'
                  className='linear-background'
                  style={{
                    height: 20,
                    background:
                      'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
                  }}
                ></StyledIconContent>
              </StyledColumns>
            </FlexRow>
          ))}
        </TopContent>
      </div>
    </>
  );
};

const StyledH1 = styled('h1')({
  height: '20px',
  width: '500px',
  marginBottom: 10,
});

const StyledDiv = styled('div')({
  maxWidth: 1120,
  margin: '20px auto',
  width: 1120,
});

export const DetailView = () => {
  return (
    <>
      <StyledDiv>
        <StyledH1 className='linear-background'></StyledH1>
        <StyledH1 className='linear-background'></StyledH1>
        <FlexRow size='xxs' justify='space-between' style={{ height: 100 }}>
          <FlexRow size='xxs'>
            <FullNameAvatar className='linear-background'></FullNameAvatar>
            <FlexColumn align='start' size='xxs'>
              <div
                className='linear-background'
                style={{ height: 20, width: 222 }}
              ></div>
              <div
                className='linear-background'
                style={{ height: 20, width: 100 }}
              ></div>
            </FlexColumn>
          </FlexRow>
        </FlexRow>
        <StyledDivider />
        <FlexColumn align='start' size='xxs'>
          <div
            className='linear-background'
            style={{ height: 50, width: '-webkit-fill-available' }}
          ></div>
          <div
            className='linear-background'
            style={{ height: 20, width: '-webkit-fill-available' }}
          ></div>
        </FlexColumn>
      </StyledDiv>
    </>
  );
};

const StyleNewRow = styled(StyledRow)({
  display: 'flex',
  background: 'white',
  padding: 20,
  justifyContent: 'space-between',
  borderBottom: '1px solid #8080802b'
});

export const TabView = () => {
  const loadingCount = [1, 2];
  return (
    <>
      <Container>
        <StyledHomePage style={{padding:'0px'}}>
          {loadingCount.map(() => (
            // eslint-disable-next-line react/jsx-key
            <StyleNewRow size='sm' align='start' style={{}}>
              <FlexColumn size='xs'>
                <FlexColumn align='start' size='xxs' style={{ marginTop: 30 }}>
                  <div
                    className='linear-background'
                    style={{ height: 20, width: 600 }}
                  ></div>
                  <div
                    className='linear-background'
                    style={{ height: 20, width: 100 }}
                  ></div>
                  <div
                    className='linear-background'
                    style={{ height: 20, width: 400 }}
                  ></div>
                  <div
                    className='linear-background'
                    style={{ height: 20, width: 400 }}
                  ></div>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn size='xs'>
                <StyledPostImages
                  style={{
                    width: '116px',
                    height: '100px',
                  }}
                  className='linear-background'
                ></StyledPostImages>
              </FlexColumn>
            </StyleNewRow>
          ))}
        </StyledHomePage>
      </Container>
    </>
  );
};
