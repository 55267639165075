import { Tab, Tabs, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { getMyPostListStore } from 'store/getMyPostListStore';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import {
  StyledDivider,
  StyledIconContent,
  StyledPostImages,
  StyledSvgIcon,
  StyledText,
  TextMargin,
} from 'views/component/element/CardView';
import TimeElapsed from 'utils/time-utils';
import { MappingPostType, PostDto, PostListParams } from 'models/post';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { MessageIcon } from 'views/component/element/Icons';
import { useNavigate } from 'react-router-dom';
import { usePostViewStore } from 'store/post-view';
import { Avatar } from 'views/component/ProfileMenu';
import { initialValue } from 'gateways/util';
import { ContentLinear } from 'views/component/Loader/Loading';
import { getMyPostDetailsStore } from 'gateways/post';
import { structureViewPostUrl } from 'helper';
import { StyledNoRecordContent } from '../topics/topics';

export const StyledSearchTopic = styled(FlexRow)({
  maxWidth: 1120,
  margin: '20px auto 0',
  flexDirection: 'column',
  alignItems: 'start',
  width: '100%',
  overflowX: 'hidden'
});

export const StyledH1 = styled('div')({
  fontSize: 42,
  fontWeight: '500',
});

export const StyledSpan = styled('span')({
  opacity: 0.4,
});

export const StyledPost = styled(FlexRow)({
  justifyContent: 'space-between',
  marginBottom:10
});

export const StyledTitle = styled(FlexRow)({
  fontSize: 14,
  fontWeight: 800,
  cursor: 'pointer',
  textTransform: 'capitalize',
  width:'fit-content',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
});

export const StyledSubTitle = styled(FlexRow)({
  fontSize: 14,
  color: '#6B6B6B',
  cursor: 'pointer',
  textTransform: 'capitalize',
  width:'fit-content',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
});

export const StyledRow = styled(FlexRow)({
  flexDirection: 'column',
  width: '100%',
  borderBottom: '1px solid #80808030;',
  alignItems: 'start',
  padding: '0 24px',
  marginBottom: 24,
});

export const StyledAvatarRow = styled(FlexRow)({
  columnGap: 16,
});

const AdsStyle = styled(FlexRow)({
  fontSize:14,
  fontWeight:'bold',
  marginBottom:12,
  opacity:0.6,
  width: '-webkit-fill-available',
  marginTop:10
});

const StyledDiv = styled('div')({
  marginBottom:10
});

function Author({ userId,institution }: { userId: string,institution?:string }) {
  const { userProfiles } = usePostViewStore();
  const user = userProfiles.get(userId.toLowerCase());
  return (
    <StyledAvatarRow size='xs'>
      <Avatar sx={{ height: 20, width: 20 }}>
        <StyledText
          sx={{
            fontSize: 8,
            textAlign: 'center',
            color: '#ffffff',
            marginTop: '1px !important',
          }}
        >
          {initialValue(user?.name)}
        </StyledText>
      </Avatar>
      <StyledText
        sx={{
          fontSize: 12,
          marginLeft: '-6px',
          textOverflow: 'unset',
          width: 'unset',
          textTransform: 'capitalize',
        }}
      >
        {user?.name ? user?.name : 'Unknown User'}
      </StyledText>
      {institution && (<StyledText sx={{
        fontSize: 12,
        marginLeft:'-8px',
      }}>{`in ${institution}`}</StyledText>)}
    </StyledAvatarRow>
  );
}

type PostDetails = {
  postList?: PostDto[];
  post?: PostDto;
  isAd?:boolean
};

function CommanContainer(data: PostDetails) {
  const navigate = useNavigate();
  return (
    <>
      <StyledPost size='xs'>
        <FlexColumn size='xs'>
          {!data.isAd && <Author userId={data.post.user_id} institution={data.post.institute_name}/>}
          <StyledTitle
            size='xs'
            onClick={() =>
              navigate(
                structureViewPostUrl(
                  data?.post.route_path,
                  data?.post.institute_name
                )
              )
            }
          >
            {data.post.title}
          </StyledTitle>
          <StyledSubTitle
            size='xs'
            onClick={() =>
              navigate(
                structureViewPostUrl(
                  data?.post.route_path,
                  data?.post.institute_name
                )
              )
            }
          >
            {data.post.subtitle}
          </StyledSubTitle>
          <FlexRow size='xs'>
            <StyledIconContent
              size='xs'
              sx={{
                color: '#6d6d6d',
                fontWeight: 'unset',
                marginTop: 0,
              }}
            >
              <TimeElapsed
                postedTime={
                  new Date(data.post?.published_date_time)
                }
              />
            </StyledIconContent>
            {data.post.is_allow_reactions && (<FlexColumn size='xs'>
              <StyledIconContent size='xs'>
                {data?.post.reactions?.metadata.map(reactions => (
                  <FlexRow size='xs' key={reactions.type}>
                    {reactions.type === 'Like' && (
                      <>
                        {data.post.reactions?.collection.find(
                          likedUser =>
                            likedUser?.user_id.toLowerCase() === 'like'
                        ) ? (
                            <StyledSvgIcon>
                              <ThumbUpAltIcon />
                            </StyledSvgIcon>
                          ) : (
                            <StyledSvgIcon>
                              <ThumbUpOffAltIcon />
                            </StyledSvgIcon>
                          )}
                      </>
                    )}
                    <TextMargin>{reactions?.count}</TextMargin>
                  </FlexRow>
                ))}
                {data.post.reactions.metadata.length === 0 && (
                  <FlexRow size='xs'>
                    <StyledSvgIcon>
                      <ThumbUpOffAltIcon />
                    </StyledSvgIcon>
                    <TextMargin>0</TextMargin>
                  </FlexRow>
                )}
              </StyledIconContent>
            </FlexColumn>)}
            {data.post.is_allow_comments && (<FlexColumn size='xs'>
              <StyledIconContent size='xs'>
                <StyledSvgIcon
                  sx={{
                    path: {
                      fill: 'rgb(233, 90, 8)',
                    },
                  }}
                >
                  <MessageIcon />
                </StyledSvgIcon>
                {data?.post.comments_count || 0}
              </StyledIconContent>
            </FlexColumn>)}
          </FlexRow>
        </FlexColumn>
        <FlexColumn size='xs'>
          <StyledPostImages
            onClick={() =>
              navigate(
                structureViewPostUrl(
                  data?.post.route_path,
                  data?.post.institute_name
                )
              )
            }
          >
            {data.post.cover_image_link && (
              <img
                src={data.post.cover_image_link}
                alt='eventImage'
                style={{
                  width: '116px',
                  height: '100px',
                  objectFit: 'cover',
                }}
              />
            )}
          </StyledPostImages>
        </FlexColumn>
      </StyledPost>
    </>
  );
}

function Ads(props: PostDetails) {
  return (<><CommanContainer post={props.post} isAd={props.isAd}/></>
  );
}

const SearchTopics = () => {
  const [postType, setPostType] = useState<string>('Posts');
  const tabNameList: string[] = ['Posts', 'Events', 'News'];
  const [page, setPage] = useState(1);

  const [paginationLastPageSize, setLastPageSize] = useState<number>(0);
  const { searchQueryString } = getMyPostListStore();
  const [postList, setPostList] = useState([]);
  const [adList, setAdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPostList([]);
    getPostList();
  }, [searchQueryString, page, postType]);

  async function getPostList() {
    setIsLoading(true);
    const params: PostListParams = {
      page,
      limit: 25,
      tagId: null,
      query: searchQueryString,
    };

    const postData = await getMyPostDetailsStore(params);
    setLastPageSize(postData.metadata.last_page);
    const filteredData = postData.collection.filter(
      post => post.type === MappingPostType[postType]
    );
    const filterAds = postData.collection.filter(
      post => post.type === 'Advertisement'
    );
    setAdList(filterAds);
    if (postList[0]?.type == MappingPostType[postType]) {
      setPostList(previousData => [...previousData, ...filteredData]);
    } else {
      setPostList(filteredData);
    }
    setIsLoading(false);
  }

  //infinity scroll
  window.onscroll = function () {
    const tolerance = 1;
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (Math.abs(scrollPosition - pageHeight) <= tolerance) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    if (paginationLastPageSize <= page || isLoading) {
      return;
    }

    setPage(page + 1);
  };

  return (
    <>
      <StyledSearchTopic size='sm'>
        <StyledRow size='xs'>
          <StyledH1 style={{marginBottom:30}}>
            <StyledSpan>Results for </StyledSpan> {searchQueryString as any}
          </StyledH1>
          <>
            {adList?.length > 0 &&
              adList.map((data,index) => (
                
                <><AdsStyle size='xs'>Sponsored </AdsStyle><div key={data.post_id} style={{width: '-webkit-fill-available'}}>
                  <StyledDiv>
                    <div style={{color:'black',fontWeight:'bold',fontSize:16}}>{data?.institute_name}</div>
                    <Ads post={data} isAd={true}/>
                  </StyledDiv>
                  {adList.length != (index+1) && (<StyledDivider />)}
                </div></>
              ))}
          </>
          <Tabs
            value={postType}
            TabIndicatorProps={{
              style: { backgroundColor: '#0f172a', width: '40px' },
            }}
            sx={{
              '& .MuiTab-root': {
                color: '#969696',
                fontWeight: '600',
                paddingLeft: '0px',
                fontFamily: 'Poppins',
                WebkitAlignItems: 'flex-start',
                width: '40px',
                minWidth: 80,
                '&.Mui-selected': {
                  color: '#0f172a',
                },
              },
            }}
          >
            {tabNameList.map(tab => (
              <Tab
                sx={{ textTransform: 'none' }}
                key={tab}
                label={tab}
                value={tab}
                onClick={() => {
                  setPostList([]);
                  setPage(1);
                  setPostType(tab);
                }}
              />
            ))}
          </Tabs>
        </StyledRow>
        <FlexColumn
          size='xs'
          style={{
            width: '100%',
            height: !postList?.length ? '88vh' : '',
            padding: '0 24px',
          }}
        >
          {isLoading ? (
            <>
              <ContentLinear />
            </>
          ) : (
            <>
              {postList?.length ? (
                postList.map(data => (
                  <div key={data.post_id}>
                    <CommanContainer post={data} />
                    <StyledDivider />
                  </div>
                ))
              ) : (
                <FlexColumn size='xs'>
                  <StyledNoRecordContent size='xs'>
                    Make sure all words are spelled correctly.
                  </StyledNoRecordContent>
                  <StyledNoRecordContent size='xs'>
                    Try different keywords.
                  </StyledNoRecordContent>
                  <StyledNoRecordContent size='xs'>
                    Try more general keywords.
                  </StyledNoRecordContent>
                </FlexColumn>
              )}
            </>
          )}
        </FlexColumn>
      </StyledSearchTopic>
    </>
  );
};

export default SearchTopics;
