import styled from '@emotion/styled';
import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { MappingPostType, PostCollectDto, PostListParams, PublishType } from 'models/post';
import { useEffect, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CalendarIcon,
  MessageIcon,
} from 'views/component/element/Icons';
import { PostStatus } from '../../../models/post';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import ConfirmationDialog from 'views/dialogs/confirmation-dialog';
import { MyContentStatekeys, useMyContentPostStore } from 'store/myContentStore';
import { ContentLinear } from 'views/component/Loader/Loading';
import { structureViewPostUrl } from 'helper';
import { StyledIconContent, StyledSvgIcon, StyledText, TextMargin } from 'views/component/element/CardView';
import { Avatar } from 'views/component/ProfileMenu';
import { initialValue } from 'gateways/util';
import { useAccountStore } from 'store/account';
import { FlexRow } from 'views/component/styled/flex';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { resetEditor } from 'store/post';
import DatePicker from 'views/component/element/DatePicker';

const MyContentLayout = styled('div')({
  height: '100%',
  width: '100%',
  maxWidth: 1120,
  overflowY: 'scroll',
  margin: '40px auto 0',
  flex: '1 1',
});

const MyContentTitle = styled('div')({
  fontSize: 20,
  fontWeight: 600,
  color: '#242424',
  textAlign: 'left',
  marginBottom: '32px',
  fontFamily: 'Poppins',
});

const RadioButton = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Poppins !important',
  },
});

const MyContents = styled('div')({
  display: 'flex',
  marginTop: '10px',
  width: '100%',
  justifyContent: 'space-between',
});

const EventDetail = styled('div')({
  width: '80%',
});

const PostTitle = styled('div')({
  margin: '20px 25px 0px 0px',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  textAlign: 'left',
  color: '#242424',
  cursor: 'pointer',
  textOverflow:'ellipsis',
  overflow:'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical'
});

const PostSubTitle = styled('div')({
  margin: '8px 25px 16px 0px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'italic',
  textAlign: 'left',
  color: '#6E6E6E',
  cursor: 'pointer',
  textOverflow:'ellipsis',
  overflow:'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

const PostContent = styled('div')({
  height: '46px',
  margin: '0px 14px auto 0px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  textAlign: 'left',
  color: '#242424',
});

const ActionIconsGroup = styled('div')({
  display: 'inline-flex',
  margin: '0px 6px 6px 0px',
  width: '100%',
  alignItems: 'center',
  columnGap:16
});

const Calendar = styled('div')({
  margin: '16px 0px',
  display: 'flex',
});

const EventDate = styled('div')({
  marginLeft: '8px',
  fontSize: '12px',
  marginTop: '1.5px',
  color: '#6d6d6d',
});

const DraftStatus = styled('span')({
  color: '#6d6d6d',
  fontWeight: 100,
  fontSize: 12,
});

const EventImage = styled('div')({
  margin: '40px 0px 6px 14px',
  cursor: 'pointer',
});

const DropDownMenuIcon = styled('div')({
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer'
});

const Nodata = styled('div')({
  textAlign: 'center',
  marginTop: '5%',
});

const CreateLink = styled('span')({
  cursor: 'pointer',
  color: '#0b57d0',
  hover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const DatePickerLayout = styled('div')({
  marginTop: '8px'
});

function MyContent() {
  const [eventStatus, setEventStatus] = useState<string>('All');
  const eventState: string[] = ['All', 'Draft', 'Published'];
  const tabNameList: string[] = ['Posts', 'Events', 'News', 'Ads'];
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openConfirm, setOpen] = useState(false);
  const [post, setPost] = useState(null);
  const {fetchMyContentList , existingMyContentList , refetchMyContentList , resetMyContentList, content} = useMyContentPostStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');
  const { user } = useAccountStore();
  const [dateFilter, setDateFilter] = useState({
    from: null,
    to: null
  });
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);

  const changeEventType = newTab => {
    if (isLoading) {
      return;
    }
    setSearchParams({tab: newTab});
  };

  const changeEventStatus = status => {
    setEventStatus(status);
  };


  useEffect(() => {
    if(!selectedTab){
      setSearchParams({tab: tabNameList[0]});
      return;
    }
    fetchMyContent();
  }, [selectedTab , eventStatus, dateFilter]);


  async function fetchMyContent() {
    const params: PostListParams = {
      limit: 25,
      type: MappingPostType[selectedTab],
      statuses:
        eventStatus === 'All'
          ? []
          : eventStatus === 'Published'
            ? ['Published&statuses=Scheduled']
            : [eventStatus]
    };
    if(isDateRangeSelected){
      params.fromDate = dateFilter.from,
      params.toDate = dateFilter.to;
    }
    setIsLoading(true);
    await existingMyContentList(selectedTab as MyContentStatekeys , params);
    setIsLoading(false);
  }
  

  //infinity scroll
  window.onscroll = function () {
    const tolerance = 1;
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (Math.abs(scrollPosition - pageHeight) <= tolerance && !isLoading) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    const params: PostListParams = {
      limit: 25,
      type: MappingPostType[selectedTab],
      statuses:
        eventStatus === 'All'
          ? []
          : eventStatus === 'Published'
            ? ['Published&statuses=Scheduled']
            : [eventStatus],
    };
    if(isDateRangeSelected){
      params.fromDate = dateFilter.from,
      params.toDate = dateFilter.to;
    }
  
    fetchMyContentList(selectedTab as MyContentStatekeys, params);
  };

  const navigateToCreate = () => {
    navigate(`/${MappingPostType[selectedTab]}/new`);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, data: any) => {
    setAnchorEl(event.currentTarget);
    setPost(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirmClose = event => {
    if (event.isContentRemoved) {
      refetchMyContentList(selectedTab as MyContentStatekeys, event.postId);
    }
    setOpen(false);
  };

  const viewSelectedPostDetail = (data :PostCollectDto ) => {
    resetMyContentList(); 
    resetEditor();
    if (data.status === PublishType.Published) {
      navigate(structureViewPostUrl(data?.route_path, data?.institute_name));
    } else {
      navigate(`/${MappingPostType[selectedTab]}/${data.post_id}/edit`);
    }
  };

  return (
    <MyContentLayout>
      <MyContentTitle>My Content</MyContentTitle>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          width:'100%'
        }}
      >
        <Tabs
          value={selectedTab}
          TabIndicatorProps={{
            style: { backgroundColor: '#0f172a', width: '40px' },
          }}
          style={{width:'50%'}}
          sx={{
            '& .MuiTab-root': {
              color: '#969696',
              fontWeight: '600',
              paddingLeft: '0px',
              fontFamily: 'Poppins',
              WebkitAlignItems: 'flex-start',
              width: '40px',
              minWidth: 80,
              '&.Mui-selected': {
                color: '#0f172a',
              },
            },
          }}
        >
          {tabNameList.map(tab => (
            <Tab
              sx={{ textTransform: 'none' }}
              key={tab}
              label={tab}
              value={tab}
              onClick={() => changeEventType(tab)}
            />
          ))}
        </Tabs>
        <RadioButton style={{alignItems:'center',width:'50%',marginLeft:'100px'}}>
          <RadioGroup row value={eventStatus}>
            {eventState.map(status => (
              <FormControlLabel
                key={status}
                label={
                  <Typography
                    style={{ fontSize: '14px', fontFamily: 'Poppins' }}
                  >
                    {status}
                  </Typography>
                }
                value={status}
                control={<Radio />}
                onClick={() => changeEventStatus(status)}
              />
            ))}
          </RadioGroup>
          <DatePickerLayout style={{marginTop: '-24px'}}>
            <DatePicker setDateFilter={setDateFilter} setIsDateRangeSelected={setIsDateRangeSelected}/>
          </DatePickerLayout>
        </RadioButton>
       
      </Box>

      <div>
        {content?.map(data => {
          return (
            <>
              <MyContents key={data.post_id}>
                <EventDetail>
                  <FlexRow size='xs'>
                    <Avatar style={{width:30,height:30,fontSize:12}}>{initialValue(user?.name)}</Avatar> 
                    <StyledText style={{fontSize:14}}>{user?.name}</StyledText>
                    {data?.institute_name && (<StyledText style={{fontSize:14}}>{`in ${data?.institute_name}`}</StyledText>)}
                  </FlexRow>
                  <div>
                    <PostTitle
                      onClick={() => viewSelectedPostDetail(data)}
                    >
                      {data?.title ? data.title : data.seo_title}  
                      {data.status === PublishType.Draft && (
                        <DraftStatus>{' - Draft'}</DraftStatus>
                      )}
                      {data.status === PublishType.Scheduled && (
                        <DraftStatus>{' - Scheduled'}</DraftStatus>
                      )}
                    </PostTitle>
                    {(data.subtitle || data.seo_description) && (
                      <PostSubTitle onClick={() => viewSelectedPostDetail(data)}>{data.seo_description ? data.seo_description : data.subtitle}</PostSubTitle>
                    )}
                    {data['convert_content'] && (
                      <PostContent>
                        <LinesEllipsis
                          text={data['convert_content']}
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                        />
                      </PostContent>
                    )}
                  </div>
                  <ActionIconsGroup >
                    <Calendar>
                      <CalendarIcon />
                      <EventDate>
                        {formatter.format(
                          data.status === PostStatus.Draft
                            ? new Date(
                              data?.updated_date_time ||
                                  data?.created_date_time
                            )
                            : new Date(
                              data.published_date_time || data?.scheduled_on
                            )
                        )}
                      </EventDate>
                    </Calendar>
                    <StyledIconContent size='xs'>
                      {data?.reactions?.metadata.map(reactions => (
                        <FlexRow size='xs' key={reactions.type}>
                          {reactions.type === 'Like' && (
                            <div style={{opacity: ['draft','scheduled'].includes(data?.status.toLowerCase()) ? '0.6':'1',cursor:['draft','scheduled'].includes(data.status.toLowerCase()) ? 'not-allowed':''}}>
                              {data.reactions?.collection.find(
                                likedUser =>
                                  likedUser?.user_id.toLowerCase() ===
                                        user.userId?.toLowerCase()
                              ) ? (
                                  <StyledSvgIcon>
                                    <ThumbUpAltIcon />
                                  </StyledSvgIcon>
                                ) : (
                                  <StyledSvgIcon>
                                    <ThumbUpOffAltIcon />
                                  </StyledSvgIcon>
                                )}
                            </div>
                          )}
                          <TextMargin>{reactions?.count}</TextMargin>
                        </FlexRow>
                      ))}
                      {data.reactions.metadata.length === 0 && (
                        <FlexRow size='xs' style={{opacity: ['draft','scheduled'].includes(data?.status.toLowerCase()) ? '0.6':'1',cursor:['draft','scheduled'].includes(data.status.toLowerCase()) ? 'not-allowed':''}}>
                          <StyledSvgIcon>
                            <ThumbUpOffAltIcon />
                          </StyledSvgIcon>
                          <TextMargin>0</TextMargin>
                        </FlexRow>
                      )}
                    </StyledIconContent>
                    <StyledIconContent size='xs' style={{opacity: ['draft','scheduled'].includes(data?.status.toLowerCase()) ? '0.6':'1',cursor:['draft','scheduled'].includes(data.status.toLowerCase()) ? 'not-allowed':''}}>
                      <StyledSvgIcon
                        sx={{
                          path: {
                            fill: 'rgb(233, 90, 8)',
                          },
                        }}
                        
                      >
                        <MessageIcon />
                      </StyledSvgIcon>
                      {data?.comments_count || 0}
                    </StyledIconContent>
                    <DropDownMenuIcon
                      onClick={event => handleClick(event, data)}
                    >
                      <MoreHorizIcon />
                    </DropDownMenuIcon>
                  </ActionIconsGroup>
                </EventDetail>
                {data?.cover_image_link && (
                  <EventImage onClick={() => viewSelectedPostDetail(data)}>
                    <img
                      src={data?.cover_image_link}
                      style={{ width: '200px', height: '134px',objectFit:'cover',borderRadius:'4px' }}
                    />
                  </EventImage>
                )}
              </MyContents>
              <Divider></Divider>
            </>
          );
        })}
      </div>
      {isLoading && (
        <ContentLinear />
      )}

      {content?.length <= 0 && !isLoading  && (
        <Nodata>
          <div>You do not have any {selectedTab} created yet.</div>
          Click{' '}
          <CreateLink className='create' onClick={navigateToCreate}>
            here
          </CreateLink>{' '}
          to create your first {selectedTab}.
        </Nodata>
      )}

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {post?.status != PublishType.Published ? (
          <MenuItem
            onClick={() =>
              viewSelectedPostDetail(post)
            }
          >
            Edit {post?.status == PublishType.Scheduled ? 'scheduled' : 'draft'}
          </MenuItem>
        ) :
          (<MenuItem onClick={() => viewSelectedPostDetail(post)}>
            View publish
          </MenuItem>
          )}
        <MenuItem
          onClick={handleClickOpen}
          sx={{ color: '#c94a4a !important' }}
        >
          {post?.status == PublishType.Published
            ? 'Archive'
            : post?.status == PublishType.Scheduled
              ? 'Delete scheduled'
              : 'Delete draft'}
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openConfirm}
        onClose={handleConfirmClose}
        post={post}
      />
    </MyContentLayout>
  );
}

export default MyContent;
