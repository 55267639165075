import {
  Box,
  Dialog,
  IconButton,
  MenuItem,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { publishPost, schedulePost } from 'gateways/post';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostStore } from 'store/post';
import {
  Button,
  Form,
  Input,
  Select,
  TextareaAutosize,
  Checkbox,
  TagAutocomplete
} from 'views/component/element';
import { CancelIcon, InfoIcon } from 'views/component/element/Icons';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import { SlideUpTransition } from '../Transitions';
import './PublishDialog.scss';
import { PostActionDto, PostCreateOptions } from 'models/post';
import moment from 'moment';
import { handleBase64ToFile, structureViewPostUrl } from 'helper';
import { uploadFile } from 'gateways/util';

const UnlistDayOptions = [
  { value: 7, label: '7 Days' },
  { value: 15, label: '15 Days' },
  { value: 30, label: '1 Month' },
  { value: 60, label: '2 Months' },
  { value: 90, label: '3 Months' },
  { value: 180, label: '6 Months' },
  { value: 365, label: '1 Year' },
];

const UnlistPost = styled(FlexRow)({
  marginLeft: -12,
});

const Error = styled(Alert)({
  backgroundColor: 'transparent',
  padding: 0
});

export const ImageContainer = styled('div')` 
  height: 264px;
  object-fit: cover;
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  background: no-repeat;
  background-size: cover;
`;

const CoverImageContainer = styled('div')`
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: white;
  width: 160px;
  height: 40px;
  padding: 10px 0;
  border-radius: 200px;
  border: solid 1px #949191;
  background-color: rgba(0, 0, 0, 0.82);
  cursor: pointer;
`;

const TitleTextarea = styled(TextareaAutosize)({
  padding: 0,
  border: 0,
  fontSize: 20,
  fontWeight: 500
});

const SubtitleTextarea = styled(TextareaAutosize)({
  padding: 0,
  border: 0,
  fontSize: 16
});

const OverallDialogContainer = styled('div')({
  maxWidth: '1120px',
  margin: '0 auto',
});

const SelectedImageContainer = styled(FlexRow)({
  flexWrap: 'wrap',
  rowGap: 8,
});

const DoneContainer = styled(FlexRow)({
  cursor: 'pointer',
  color: '#1d4ed8',
});

const ContentContainer = styled(FlexRow)({
  alignItems: 'start',
});

const InputDivider = styled(FlexColumn)({
  borderBottom: '1px solid rgba(0, 0, 0, .15)',
  padding: '8px 0 4px'
});

export const Count = styled('div')({
  color: '#909090',
  fontSize: 10
});

const NoImageContainer = styled('div')({
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.54)',
  textAlign: 'center',
  height: '250px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 80px',
  background: '#fafafa',
});

const Notes = styled('div')({
  fontSize: 14,
  textAlign: 'justify',
  color: '#676464',
  opacity: 0.8,
});

const TopicsContainer = styled('div')({
  fontSize: 14,
  color: '#334155',
});

const StyledColumns = styled(FlexColumn)({
  width: '50%',
});

const DateRows = styled(FlexRow)({
  width: '100%'
});

const SelectedImagesStyled = styled(FlexColumn)({
  height: 264,
});

const StyledDialogTitle = styled('h6')({
  fontWeight: '700',
  fontSize: 16,
  fontFamily: 'Poppins',
});

type PublishPostDialogProps = {
  open?: boolean;
  onClose: () => void;
};

function PublishDialog(prop: PublishPostDialogProps) {
  const { saveDraft, post, postImages, extractImagesAndTexts} = usePostStore();
  const navigate = useNavigate();

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isSchedule, setIsSchedule] = useState(false);
  const [showCoverImagePicker, setShowCoverImagePicker] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    extractImagesAndTexts();
    setShowCoverImagePicker(false);
  },[prop.open]);

  const handleClose = () => {
    setShowCoverImagePicker(false);
    prop.onClose();
  };

  const postForm = {
    scheduled_on: '',
    title: post?.title || '',
    subtitle: post?.subtitle || '',
    venue: '',
    start_date_time: null,
    end_date_time: null,
    isUnlistPost: false,
    unlist_schedule: '',
    tags: post?.tags
  };

  const handleImageChange = async () => {
    const selectedImage = postImages[selectedImageIndex];
    let imageUrl = selectedImage;
    if (selectedImage.startsWith('data:image/')) {
      const file = handleBase64ToFile(selectedImage);
      const result = await uploadFile(file);
      imageUrl = result.location;
    }
    await saveDraft({ cover_image_link: imageUrl });
    setShowCoverImagePicker(false);
  };
  const handleSchedule = () => {
    setIsSchedule(!isSchedule);
    setErrors([]);
  };

  const handleChange = async (values: typeof postForm) => {
    const options = {
      title: values.title || 'Untitled Story',
      subtitle: values.subtitle,
      tags: values.tags
    } as PostCreateOptions;

    if (post.title != options.title || post.subtitle != options.subtitle) {
      options.is_title_synthesized = false;
    }

    if (post.type === 'Event') {
      options.venue = values.venue;
      options.start_date_time = values.start_date_time;
      options.end_date_time = values.end_date_time;
    }
    setLoading(true);
    await saveDraft(options);
    setLoading(false);
  };

  const handlePublish = async (values: typeof postForm) => {
    const errors: string[] = [];

    if (post.type === 'Event') {
      if (!values.start_date_time || !values.end_date_time) {
        errors.push('Event schedule is required');
      }

      if (!values.venue) {
        errors.push('Venue of event is required');
      }

      if (values.start_date_time && values.end_date_time) {
        if (moment(values.start_date_time).isAfter(values.end_date_time)
          || moment(values.end_date_time).diff(values.start_date_time, 'months') > 6
          || moment(values.start_date_time).isBefore(moment(moment.now()).subtract(6, 'months'))
          || moment(values.end_date_time).isAfter(moment(moment.now()).add(12, 'months'))) {
          errors.push('Event schedule date range is invalid');
        }
      }
    }

    if (values.isUnlistPost && !values.unlist_schedule) {
      errors.push('Please choose a date to schedule the unlisting');
    }

    if (isSchedule) {
      if (!values.scheduled_on) {
        errors.push('Pick a date to schedule your publish');
      }
      if (values.scheduled_on) {
        if (moment(values.scheduled_on).isBefore(moment.now())
          || moment(values.scheduled_on).isAfter(moment(moment.now()).add(3, 'months'))) {
          errors.push('You can only schedule for dates in the near future');
        }
      }
    }

    if(!post.title ||  post.seo_title == 'Untitled Story' || post.title == 'Untitled story'){
      errors.push(`You Cannot publish ${post.type} without content`);
    }

    setErrors(errors);

    if (errors?.length) {
      return;
    }
    setLoading(true);
    const options = {
      title: values.title || 'Untitled story',
      subtitle: values.subtitle,
      tags: values.tags
    } as PostActionDto;

    if (isSchedule) {
      options.scheduled_on = values.scheduled_on;
    }

    if (post.type === 'Event') {
      options.venue = values.venue;
      options.start_date_time = values.start_date_time;
      options.end_date_time = values.end_date_time;
    }

    if (options.title != post.title || options.subtitle != post.subtitle) {
      options.is_title_synthesized = false;
    }

    if (values.isUnlistPost) {
      options.lifetime = moment(isSchedule ? values.scheduled_on : moment.now()).add(values.unlist_schedule, 'days').format('yyyy-MM-DD');
    }

    if (isSchedule) {
      await schedulePost(post.post_id, options);
      setLoading(false);
      prop.onClose();
      navigate('/create', { replace: true });
    } else {
      await publishPost(post.post_id, options);
      setLoading(false);
      prop.onClose();
      navigate(`${structureViewPostUrl(post?.route_path, post?.institute_name)}`, { replace: true });
    }
  };

  return (
    <>
      <Dialog
        fullScreen
        open={prop.open}
        onClose={handleClose}
        TransitionComponent={SlideUpTransition}
        className='post-publish'
      >
        <OverallDialogContainer>
          <DialogTitle>
            <FlexRow size='xs' justify='space-between'>
              <StyledDialogTitle>{post?.type} Preview</StyledDialogTitle>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <CancelIcon />
              </IconButton>
            </FlexRow>
          </DialogTitle>
          <DialogContent>
            <Form initialValues={postForm} onSubmit={handlePublish} validate={handleChange}>
              {({ values }) => (
                <ContentContainer size='md'>
                  <StyledColumns size='xs'>
                    {postImages?.length > 0 && !showCoverImagePicker && (
                      <ImageContainer
                        sx={{ backgroundImage: `url(${post.cover_image_link})` }}
                      >
                        <CoverImageContainer
                          onClick={() => setShowCoverImagePicker(true)}
                        >
                          Change Cover Image
                        </CoverImageContainer>
                      </ImageContainer>
                    )}
                    {!postImages?.length && (
                      <NoImageContainer>
                        Include a high-quality image in your story to make it more
                        inviting to readers.
                      </NoImageContainer>
                    )}
                    {postImages?.length > 0 && showCoverImagePicker && (
                      <SelectedImagesStyled size='sm'>
                        <DoneContainer
                          size='xs'
                          onClick={handleImageChange}
                        >
                          Done
                        </DoneContainer>
                        <SelectedImageContainer size='xs'>
                          {postImages?.map((res, index) => (
                            <FlexColumn
                              size='xs'
                              key={index}
                              onClick={() => {
                                setSelectedImageIndex(index);
                              }}
                            >
                              <img
                                src={res}
                                width={'126px'}
                                height={'86px'}
                                style={{
                                  border:
                                    selectedImageIndex === index
                                      ? 'solid 3px #4c96f6'
                                      : '',
                                  objectFit:'cover'
                                }}
                              />
                            </FlexColumn>
                          ))}
                        </SelectedImageContainer>
                      </SelectedImagesStyled>
                    )}
                    <FlexColumn size='md'>
                      <InputDivider size='xxs'>
                        <TitleTextarea
                          name='title'
                          maxLength={100}
                          placeholder='Write a preview title'
                        />
                        {values.title.length > 68 && (
                          <Count>{values.title.length}/100</Count>
                        )}
                      </InputDivider>
                      <InputDivider size='xxs'>
                        <SubtitleTextarea
                          name='subtitle'
                          maxLength={140}
                          placeholder='Write a preview subtitle...'
                        />
                        {values?.subtitle?.length > 98 && (
                          <Count> {values?.subtitle?.length}/140 </Count>
                        )}
                      </InputDivider>
                      <Notes>
                        <b>Note:</b> Changes here will affect how your story appears in public places like EduAlliance&apos;s homepage and in subscribers&apos; inboxes — not the contents of the story itself.
                      </Notes>
                    </FlexColumn>
                  </StyledColumns>
                  <StyledColumns size='md'>
                    <FlexColumn size='sm'>
                      <TopicsContainer>
                        Add or change topics (up to 5) so that readers know what
                        your {post?.type.toLowerCase()} is about
                      </TopicsContainer>
                      <TagAutocomplete name='tags' />
                    </FlexColumn>
                    {post?.type == 'Event' && (
                      <>
                        <FlexColumn size='xxs'>
                          <label>Event schedule</label>
                          <DateRows size='xs'>
                            <StyledColumns size='sm'>
                              <Input type='date' name='start_date_time' />
                            </StyledColumns>
                            <StyledColumns size='sm'>
                              <Input type='date' name='end_date_time' />
                            </StyledColumns>
                          </DateRows>
                        </FlexColumn>
                        <FlexColumn size='xxs'>
                          <label>Venue of Event</label>
                          <Input placeholder='Venue' name='venue' />
                        </FlexColumn>
                      </>
                    )}
                    <FlexColumn size='xxs'>
                      <UnlistPost size='xs'>
                        <Checkbox name='isUnlistPost' label='Unlist post later' />
                        <Tooltip
                          title={
                            <Box
                              sx={{
                                backgroundColor: '#2a3250',
                                color: '#fff',
                                padding: '10px 15px',
                                borderRadius: '4px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                fontFamily: 'Poppins',
                                fontSize: '10px',
                                lineHeight: '2.1',
                              }}
                            >
                              If specified after the unlist date your {post?.type.toLowerCase()} will
                              not come up in search results or on anywhere else on
                              our site.
                            </Box>
                          }
                          arrow
                          placement='bottom'
                        >
                          <FlexRow size='sm'>
                            <InfoIcon />
                          </FlexRow>
                        </Tooltip>
                      </UnlistPost>
                      {values.isUnlistPost && (
                        <FlexColumn size='xs'>
                          <label>Schedule a date to unlist</label>
                          <Select name='unlist_schedule'>
                            {UnlistDayOptions.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FlexColumn>
                      )}
                    </FlexColumn>
                    {isSchedule && (
                      <FlexColumn size='sm'>
                        <FlexColumn size='xxs'>
                          <label>Schedule a date to publish</label>
                          <Input type='date' name='scheduled_on' />
                        </FlexColumn>
                        <label>
                        This {post?.type.toLowerCase()} will be published automatically on the specified date mentioned .
                        </label>
                      </FlexColumn>
                    )}
                    {Boolean(errors.length) && (
                      <FlexColumn size='xxs'>
                        {errors.map(error => (
                          <Error severity='error' key={error}>{error}</Error>
                        ))}
                      </FlexColumn>
                    )}
                    <FlexRow size='sm'>
                      <Button color='success' type='submit' size='medium' style={{ cursor: loading ? 'not-allowed' : 'pointer', background: loading ? 'rgb(46, 125, 50)' : '', color: loading ? 'white' : '' }} disabled={loading}>
                        {isSchedule ? 'Schedule to publish' : 'Publish now'}
                      </Button>
                      <Button
                        variant='text'
                        color='secondary'
                        size='medium'
                        onClick={handleSchedule}
                      >
                        {!isSchedule ? 'Schedule for later' : 'Cancel scheduling'}
                      </Button>
                    </FlexRow>
                  </StyledColumns>
                </ContentContainer>
              )}
            </Form>
          </DialogContent>
        </OverallDialogContainer>
      </Dialog>
    </>
  );
}

export default PublishDialog;
