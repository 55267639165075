import { listPosts } from 'gateways/post';
import { PostListParams, PostType } from 'models/post';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import { StyledAvatarRow, StyledH1, StyledPost, StyledSubTitle, StyledTitle } from '../searchQuery/searchQuery';
import { Container, StyledHomePage, StyledRow } from './PostHome';
import { TabView } from 'views/component/Loader/Loading';
import { StyledIconContent, StyledPostImages, StyledSvgIcon, StyledText, TextMargin } from 'views/component/element/CardView';
import { structureViewPostUrl } from 'helper';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { EventIcon, HashTagIcon, MessageIcon, NewsTagIcon, PostTagIcon } from 'views/component/element/Icons';
import { usePostViewStore } from 'store/post-view';
import { Avatar } from 'views/component/ProfileMenu';
import { initialValue } from 'gateways/util';
import { styled } from '@mui/material';
import { useAccountStore } from 'store/account';

const StyledPostBorder = styled(StyledPost)({
  padding:'20px 0px',
  columnGap:24,
  margin:'0px 20px',
  borderBottom:'1px solid #e6e5e5'
});


export function Author({ userId,institution }: { userId: string,institution?:string }) {
  const { userProfiles } = usePostViewStore();
  const user = userProfiles.get(userId.toLowerCase());
  return (
    <StyledAvatarRow size='xs'>
      <Avatar sx={{ height: 20, width: 20 }}>
        <StyledText
          sx={{
            fontSize: 8,
            textAlign: 'center',
            color: '#ffffff',
            marginTop: '1px !important',
          }}
        >
          {initialValue(user?.name)}
        </StyledText>
      </Avatar>
      <StyledText
        sx={{
          fontSize: 12,
          marginLeft: '-6px',
          textOverflow: 'unset',
          width: 'unset',
          textTransform: 'capitalize',
        }}
      >
        {user?.name ? user?.name : 'Unknown User'}
      </StyledText>
      {institution && (<StyledText sx={{
        fontSize: 12,
        marginLeft:'-8px',
      }}>{`in ${institution}`}</StyledText>)}
    </StyledAvatarRow>
  );
}

const HomeTab = () => {
  const [list, setList] = useState([]);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);
  const navigate = useNavigate();
  const user = useAccountStore();

  async function getPostListByType() {
    const params: PostListParams = {
      limit: 25,
      page: page,
    };
    if (location.search.length > 0) {
      const str = location.search;
      const urlParams = new URLSearchParams(str);
      const tagId = urlParams.get('tagId');
      params.tagId = +tagId;
    } else {
      const type = location.pathname.split('/')[2];
      params.type = type as PostType;
    }

    const typeList = await listPosts(params);
    setHasNextPage(
      !(typeList.metadata.current_page === typeList.metadata.last_page)
    );
    setList(previousData => [...previousData, ...typeList.collection]);
    setIsLoading(false);
  }

  useEffect(() => {
    getPostListByType();
  }, [page, location]);

  useEffect(() => {
    setList([]);
    setIsLoading(true);
  }, [location]);

  //infinity scroll
  window.onscroll = function () {
    const tolerance = 1;
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (
      Math.abs(scrollPosition - pageHeight) <= tolerance &&
      !isLoading &&
      hasNextPage
    ) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    setPage(page + 1);
  };

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);

  function getIcons(){
    switch (location.pathname.split('/')[2]) {
    case 'Post':
      return <PostTagIcon />;
    case 'News':
      return <NewsTagIcon />;
    case 'Event':
      return <EventIcon />;
    default:
      return <HashTagIcon />;
    }
  }

  return (
    <>
      <Container>
        <StyledHomePage style={{padding:'10px 0px'}}>
          <StyledRow size='sm' align='start' style={{display:'block'}}>
            <FlexRow size='xs' style={{background:'unset',margin:'12px 0px'}}>
              {getIcons()}
              <StyledH1 sx={{ borderBottom:'none',textTransform:'capitalize',height:'48px',fontSize:'32px' }}>
                {location.pathname.split('/')[2]}
              </StyledH1>
            </FlexRow>
            
            <FlexRow size='sm' style={{display:'block'}}>
              {!list.length && isLoading ? (
                <>
                  <TabView />
                </>
              ) : (
                <>
                  {list?.map((data, i ) => (
                    <div key={data.post_id + i}>
                      <StyledPostBorder size='xs'>
                        <FlexColumn size='xs'>
                          <Author userId={data?.user_id} institution={data?.institute_name}/>
                          <StyledTitle
                            size='xs'
                            onClick={() =>
                              navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                            }
                          >
                            {data.title}
                          </StyledTitle>
                          <StyledSubTitle
                            size='xs'
                            onClick={() =>
                              navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                            }
                          >
                            {data.subtitle}
                          </StyledSubTitle>
                          <FlexRow size='sm' style={{ marginTop: 10 }}>
                            <StyledIconContent size='xs'>
                              <StyledSvgIcon>
                                <CalendarMonthIcon />
                              </StyledSvgIcon>
                              <div style={{ color: '#969696',marginTop:2 }}>
                                {formatter.format(
                                  new Date(data.published_date_time)
                                )}
                              </div>
                            </StyledIconContent>
                            {data?.is_allow_reactions && (<StyledIconContent size='xs'>
                              {data?.reactions?.metadata.map(reactions => (
                                <FlexRow size='xs' key={reactions.type}>
                                  {reactions.type === 'Like' && (
                                    <>
                                      {data.reactions?.collection.find(
                                        likedUser =>
                                          likedUser?.user_id.toLowerCase() ===
                                        user.user.userId?.toLowerCase()
                                      ) ? (
                                          <StyledSvgIcon>
                                            <ThumbUpAltIcon />
                                          </StyledSvgIcon>
                                        ) : (
                                          <StyledSvgIcon>
                                            <ThumbUpOffAltIcon />
                                          </StyledSvgIcon>
                                        )}
                                    </>
                                  )}
                                  <TextMargin>{reactions?.count}</TextMargin>
                                </FlexRow>
                              ))}
                              {data.reactions.metadata.length === 0 && (
                                <FlexRow size='xs'>
                                  <StyledSvgIcon>
                                    <ThumbUpOffAltIcon />
                                  </StyledSvgIcon>
                                  <TextMargin>0</TextMargin>
                                </FlexRow>
                              )}
                            </StyledIconContent>)}
                            {data?.is_allow_comments && (<StyledIconContent size='xs'>
                              <StyledSvgIcon
                                sx={{
                                  path: {
                                    fill: 'rgb(233, 90, 8)',
                                  },
                                }}
                              >
                                <MessageIcon />
                              </StyledSvgIcon>
                              {data?.comments_count || 0}
                            </StyledIconContent>)}
                          </FlexRow>
                        </FlexColumn>
                        <FlexColumn size='xs'>
                          {data.cover_image_link && <StyledPostImages
                            onClick={() =>
                              navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                            }
                            style={{cursor: data.cover_image_link ? 'pointer' : '',width: '116px',
                              height: '100px',}}
                          >
                            {data.cover_image_link && (
                              <img
                                src={data.cover_image_link}
                                alt='eventImage'
                                style={{
                                  width: '116px',
                                  height: '100px',
                                  objectFit: 'cover',
                                  borderRadius:10
                                }}
                              />
                            )}
                          </StyledPostImages> }
                        </FlexColumn>
                      </StyledPostBorder>
                    </div>))}
                </>
              )}
            </FlexRow>
          </StyledRow>
        </StyledHomePage>
      </Container>
    </>
  );
};

export default HomeTab;
