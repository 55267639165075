import { SerializedEditorState, SerializedElementNode, SerializedTextNode } from 'lexical';
import { create } from 'zustand';
import {
  createPost,
  getParticularPostDetails,
  updatePost,
} from 'gateways/post';
import { PostCreateOptions, PostDto } from 'models/post';
import { textTrim } from 'utils';
import { EntityResult } from 'models/ApiResult';
import { uploadFile } from 'gateways/util';
import { handleBase64ToFile } from 'helper';
import { SerializedImageNode } from 'views/component/element/Editor/nodes/ImageNode';

type State = {
  post?: PostDto;
  isSaving: boolean;
  isSaved: boolean;
  editorState?: SerializedEditorState;
  postContent?: string;
  postImages?: string[];
  postTexts?: string[];
};

type Action = {
  setDirty: (editorState: SerializedEditorState) => void;
  saveDraft: (options: Partial<PostCreateOptions>) => Promise<PostDto>;
  getPost: (postId: string) => Promise<void>;
  extractImagesAndTexts: () => void;
};

const usePostStore = create<State & Action>()((set, get) => ({
  isSaving: false,
  isSaved: false,
  setDirty: (editorState: SerializedEditorState) => {
    set({ isSaved: false, editorState });
    if (editorState) {
      const nodes = (editorState.root.children as SerializedElementNode[]).map(n => n.children).flat();
      const images = editorState.root.children.filter(n => n?.type === 'image');
      const postImages = images?.length > 0 ? images.map(i => i['src']) : (nodes.filter(n => n?.type == 'image') as SerializedImageNode[]).map(i => i.src);
      const texts = nodes.filter(n => n?.type == 'text') as SerializedTextNode[];
      const postTexts = texts.map(t => t.text);
      set(({ postImages, postTexts }));
    }
  },
  saveDraft: async (options: Partial<PostCreateOptions>) => {
    try {
      const { post, editorState, postImages, postTexts } = get();

      if (editorState) {
        const nodes = (editorState.root.children as SerializedElementNode[]).map(n => n.children).flat();

        if (!(nodes.length || post?.post_id)) {
          return;
        }
      }
      if (postImages?.length) {
        if ((!options.cover_image_link?.length || !post?.cover_image_link)) {
          // Process base64 images
          if (postImages[0].startsWith('data:image/')) {
            const file = handleBase64ToFile(postImages[0]);
            const result = await uploadFile(file);
            options.cover_image_link = result.location;
          } else {
            options.cover_image_link = postImages[0];
          }
        }
      } else {
        options.cover_image_link = null;
        if (post?.cover_image_link) {
          post.cover_image_link = null;
        }
      }
      if (!options.title && (!post || post.is_title_synthesized)) {
        options.is_title_synthesized = true;

        if (postTexts?.length) {
          options.title = textTrim(postTexts[0], 100);

          if (postTexts.length > 1) {
            options.subtitle = textTrim(postTexts[1], 140);
          }
        } else {
          options.title = 'Untitled story';
        }
      }

      if(!options.subtitle){
        options.subtitle = null;
      }

      if (!options.seo_title && (!post || post.is_seo_synthesized)) {
        options.is_seo_synthesized = true;
        options.seo_title = options.title;
        options.seo_description = options.subtitle;
      }

      if (!options.social_title) {
        options.social_title = options.title;
      }
      set({ isSaving: true, isSaved: false, postContent: options?.content });

      const handleResult = (result: EntityResult<PostDto>) => {
        if (result.succeeded) {
          set({ post: result.entity, isSaved: true , postContent: result?.entity?.content});
        }
        return result.entity;
      };

      if (!post) {
        options.is_allow_comments = options.type !== 'Advertisement';
        options.is_allow_reactions = options.type !== 'Advertisement';
        options.is_notify_facebook = false;
        options.is_notify_twitter = false;
        options.tags = [];
        const result = await createPost(options as PostCreateOptions);
        return handleResult(result);
      } else {
        const model = {
          tags: post.tags,
          content: post.content,
          cover_image_link: post.cover_image_link,
          end_date_time: post.end_date_time,
          is_allow_comments: post.is_allow_comments,
          is_allow_reactions: post.is_allow_reactions,
          is_notify_facebook: post.is_notify_facebook,
          is_notify_twitter: post.is_notify_twitter,
          is_seo_synthesized: post.is_seo_synthesized,
          is_title_synthesized: post.is_title_synthesized,
          lifetime: post.lifetime,
          region: post.region,
          scheduled_on: post.scheduled_on,
          seo_description: post.seo_description,
          seo_title: post.seo_title,
          start_date_time: post.start_date_time,
          subtitle: post.subtitle,
          title: post.title,
          type: post.type,
          venue: post.venue,
          ...options
        } as PostCreateOptions;
        const result = await updatePost(post.post_id, model);
        return handleResult(result);
      }
    } finally {
      set({ isSaving: false });
    }
  },
  getPost: async (postId: string) => {
    const { post } = get();
    // TO DO: modify API to send id in lowercase
    if (post?.post_id?.toLowerCase() !== postId) {
      const post = await getParticularPostDetails(postId);
      set({ post, postContent: post.content });
    }
  },
  extractImagesAndTexts: () => {
    const { post } = get();
    if (post?.content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(post.content, 'text/html');
      const extractedImages = Array.from(doc.querySelectorAll('img')).map(img => img.src);
      const extractedTexts = Array.from(doc.querySelectorAll('p, span, div')).map(el => el.textContent || '');

      set({
        postImages: [...extractedImages],
        postTexts: [...extractedTexts]
      });
    }
  }
}));

function resetEditor() {
  usePostStore.setState(() => ({
    editorState: undefined,
    postContent: undefined,
    post: undefined,
    isSaving: false,
    isSaved: false,
    postImages: undefined,
    postTexts: undefined
  }));
}

export { usePostStore, resetEditor };
